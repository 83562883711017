import ConfirmModal from "components/Modals/ConfirmModal";
import Pagination from "components/shared/Pagination";
import { getNotification } from "helpers/makeNotifications";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import {
	changeStatusCompanie,
	deleteCompanie,
	exportToExcel,
	listCompanies,
} from "services/companies";
import Locale from "translations";
import Company from "./Company";
import Filter from "./Filter";

export default function TourismCompanies() {
	const { backOffice, Companies, userDetails, confirmation } = Locale;
	const [companies, setCompanies] = useState([]);
	const [meta, setMeta] = useState(null);
	const [deletedCompany, setdeletedCompany] = useState();
	const [statusCompany, setStatusCompany] = useState();
	const [statusCompanyId, setStatusCompanyId] = useState();

	const [filters, setFilter] = useState({
		name: "",
		date: null,
		status: null,
		country_id: null,
	});

	let history = useHistory();
	const [confirmIsOpen, setConfirmIsOpen] = useState(false);
	const toggleConfirm = (id = null, status = null) => {
		setStatusCompanyId(id);
		setStatusCompany(status);
		setConfirmIsOpen(!confirmIsOpen);
	};
	const [isOpen, setIsOpen] = useState(false);
	const toggle = (id = null) => {
		setdeletedCompany(id);
		setIsOpen(!isOpen);
	};

	useEffect(async () => {
		const res = await listCompanies({
			...filters,
			status: filters.status?.value,
			country_id: filters.country_id?.value,
			date: filters.date ? moment(filters.date).format("YYYY/MM/DD") : null,
		});
		if (res?.status === 200) {
			setCompanies(res?.data?.data);
			setMeta(res?.data?.meta);
		}
	}, [filters]);

	const goTo = (page) => {
		setFilter({ ...filters, page: page });
	};

	const changeStatus = async (id) => {
		const res = await changeStatusCompanie(id);
		if (res.status === 200 || res.status === 201) {
			getNotification(res?.data?.message);
			setFilter({ ...filters });
			toggleConfirm();
		}
	};

	const removeCompany = async () => {
		const res = await deleteCompanie(deletedCompany);
		if (res.status === 200 || res.status === 201) {
			getNotification(res?.data?.message);
			setFilter({ ...filters });
			toggle();
		}
	};

	const AllProducts =
		companies.length > 0 ? (
			companies.map((company, index) => (
				<Company
					company={company}
					key={index}
					//changeStatus={changeStatus}
					removeCompany={toggle}
					// confirmIsOpen={confirmIsOpen}
					// setConfirmIsOpen={setConfirmIsOpen}
					changeStatus={toggleConfirm}
				/>
			))
		) : (
			<tr>
				<td colSpan="11">
					<div className="product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{backOffice.noResult}</h4>
					</div>
				</td>
			</tr>
		);
	const exportToFile = async () => {
		const res = await exportToExcel({
			...filters,
			status: filters.status?.value,
			country_id: filters.country_id?.value,
			date: filters.date ? moment(filters.date).format("YYYY/MM/DD") : null,
		});
		if (res?.status === 200 || res?.status === 201) {
			if (res.data) {
				const blob = new Blob([res.data], {
					//type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					type: res.data.type,
				});
				const objectUrl = window.URL.createObjectURL(blob);
				var anchor = document.createElement("a");
				anchor.target = "_blank";
				anchor.href = objectUrl;
				anchor.setAttribute(
					"download",
					`Tourism Company-${moment().format("DD-MM-YYYY")}`
				);
				anchor.click();
				//window.open(objectUrl);
			}
			store.addNotification({
				title: "Info!",
				message: `Export To Excel Successful`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	return (
		<>
			<div>
				<div className="d-flex justify-content-between">
					<h4 className="text-black ">{backOffice.companies}</h4>
					<ShowForPermission permission="Tourism-Company-Manage">
						<button
							className="btn btn-success py-2"
							onClick={() => history.push("/tourism-company/add-company")}
						>
							Add Company
						</button>
					</ShowForPermission>
				</div>
				<div className="d-flex">
					<Filter
						filter={filters}
						setFilter={setFilter}
						exportToFile={exportToFile}
					/>
				</div>
				<div className="px-3 our-border border-top-0">
					<table className="custom-table back-office-table table table-striped mb-0">
						<thead>
							<tr>
								<th>{backOffice.companyName}</th>
								<th> Website Slug</th>
								<th>{backOffice.country}</th>
								<th>{backOffice.email}</th>
								<th>{"Paid Later"}</th>
								{/* <th>{"Key"}</th> */}
								<th>Created at</th>
								<th>{backOffice.status}</th>
								{/* 
								<th>
									<i class="fab fa-cc-visa"></i>
								</th>

								<th>
									<i class="fas fa-user-times"></i>
								</th>

								<th>
									<i class="fas fa-briefcase"></i>
								</th>

								<th>
									<i class="fas fa-cog"></i>
								</th>
								<th>
									<i class="fas fa-house-damage"></i>
								</th>

								<th>
									<i class="fas fa-calculator"></i>
								</th>

								<th>
									<i class="fas fa-shopping-cart"></i>
								</th>
								<th>
									<i class="fas fa-mobile-alt"></i>
								</th>
								<th>
									<i class="fas fa-address-card"></i>
								</th> */}

								<ShowForPermission permission="Tourism-Company-Manage">
									{" "}
									<th>{Companies.Control}</th>
								</ShowForPermission>
							</tr>
						</thead>

						<tbody>
							{companies?.length > 0 ? (
								AllProducts
							) : (
								<tr>
									<td colSpan="15">
										<div className="product-no-data">
											<i className="fas fa-info-circle fa-lg"></i>{" "}
											<h4>{backOffice.noResult}</h4>
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
					<Pagination info={meta} goTo={goTo} items={10} />
				</div>
			</div>

			<Modal isOpen={isOpen} toggle={toggle}>
				<ModalBody className="lan  d-flex flex-column justify-content-center align-items-center p-0 py-3">
					<h4 className="py-4 font-weight-bolder">
						{confirmation.confirmDelete}
					</h4>
				</ModalBody>
				<div className="w-100 pb-5 border-top-0  text-center    p-0 ">
					<button
						className="btn btn-dark text-white rounded font-weight-bolder   mx-1  w-25"
						onClick={toggle}
					>
						{userDetails.cancel}
					</button>
					<button
						className="btn btn-danger text-white rounded font-weight-bolder mx-1   w-25"
						onClick={removeCompany}
					>
						{backOffice.delete} <i class="fas fa-trash text-white mx-2"></i>
					</button>
				</div>
			</Modal>

			<ConfirmModal
				IsOpen={confirmIsOpen}
				toggle={toggleConfirm}
				message={
					statusCompany == "active"
						? confirmation.activeCompany
						: confirmation.inactiveCompany
				}
				onConfirm={() => changeStatus(statusCompanyId)}
				type="comfirm"
			/>
		</>
	);
}
