import { ReactComponent as CountryIcon } from "assets/images/country.svg";
import { useGlobalDispatch } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import useShowPermission from "hooks/useShowPermission";
import { useEffect, useState } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Collapse } from "reactstrap";
import { getPermissions } from "services/auth";
import Locale from "translations";
import {
	ActivityLogs,
	AddNewIcon,
	AirlineIcon,
	AirlineRequestIcon,
	AirlineReservationIcon,
	AllBookings,
	AllIcon,
	Arrow,
	BankTransferIcon,
	BookingsIcon,
	CheckMarkIcon,
	CommissionsIcon,
	ContractManagementIcon,
	DatePickerIcon,
	FinancialIcon,
	GeneralConfigurationIcon,
	GroundServiceIcon,
	ManualReservationIcon,
	ManualSupplier,
	MarketingOfferIcon,
	PlatformCommissionsIcon,
	PlusIcon,
	PropertyIcon,
	SeasonsIcon,
	StatisticsIcon,
	SubscriptionPlanIcon,
	SuppliersIcon,
	TeamIcon,
	ToolsIcon,
	TourismCompanyIcon,
	TransportationIcon,
	TravelerIcon,
	VisaIcon,
} from "./Icons";

const SideBar = ({ isOpen, isMobile }) => {
	const { backOffice, services, vendor, payment } = Locale;
	// const { activeTap, setactiveTap } = useContext(ActiveTap);
	// const [activeTap, setactiveTap]=useState();
	const history = useHistory();

	let { url } = useRouteMatch();
	const dispatch = useGlobalDispatch();
	const teamManagementUsersViewPermission = useShowPermission({
		permission: ["Team-Management-Users-View", "Team-Management-Users-Manage"],
	});

	const activeTab = (value) => {
		localStorage.setItem("activetab", value);
	};
	const activeid = localStorage.getItem("activetab");
	const [routes, setRoutes] = useState([
		{
			id: 1,
			icon: <StatisticsIcon />,
			text: `Dashboard`,
			url: "/dashboard",
			permission: ["Bookings-View", "Bookings-Manage"],
			show: url.includes("dashboard"),
			active: url.includes("dashboard"),
		},
		{
			id: 2,
			icon: <TeamIcon />,
			text: `${backOffice.teamManagement}`,
			url: `/management-team/${
				teamManagementUsersViewPermission ? "users" : "groups"
			}`,
			permission: [
				"Team-Management-Users-View",
				"Team-Management-Users-Manage",
				"Team-Management-Groups-View",
				"Team-Management-Groups-Manage",
			],
			show: url.includes("management-team"),
			active: url.includes("management-team"),
		},

		{
			id: 4,
			icon: <TourismCompanyIcon />,
			text: `${backOffice.tourismCompany}`,
			url: "tourism-company",
			permission: ["Tourism-Company-View", "Tourism-Company-Manage"],
			show: url.includes("tourism-company"),
			active: url.includes("tourism-company"),
			subNavs: [
				{
					id: 44,
					url: "/tourism-company",
					text: `${backOffice.allTourismCompanies}`,
					icon: <AllIcon />,
					permission: ["Tourism-Company-View", "Tourism-Company-Manage"],
				},
				{
					id: 45,
					url: "/tourism-company/add-company",
					text: `${backOffice.newTourismCompany}`,
					icon: <AddNewIcon />,
					permission: ["Tourism-Company-Manage"],
				},
			],
		},
		{
			id: 5,
			icon: <BookingsIcon />,
			text: `${backOffice.Bookings}`,
			url: "/bookings",
			permission: [
				"Hotels-Bookings-View",
				"Hotels-Bookings-Manage",
				"Tours-Bookings-View",
				"Tours-Bookings-Manage",
				"Transfers-Bookings-View",
				"Transfers-Bookings-Manage",
				"View-Sold-Visas",
				"Manage-Sold-Visas",
			],
			show: url.includes("bookings"),
			active: url.includes("bookings"),
			subNavs: [
				{
					id: 55,
					url: "/bookings",
					text: `Hotels`,
					icon: <BookingsIcon />,
					permission: ["Hotels-Bookings-View", "Hotels-Bookings-Manage"],
				},
				{
					id: 56,
					url: "/bookings-tours",
					text: `Tours`,
					icon: <BookingsIcon />,
					permission: ["Tours-Bookings-View", "Tours-Bookings-Manage"],
				},
				{
					id: 57,
					url: "/bookings-transfers",
					text: `Transfers`,
					icon: <BookingsIcon />,
					permission: ["Transfers-Bookings-View", "Transfers-Bookings-Manage"],
				},
				{
					id: 58,
					url: "/bookings-sold-visas",
					text: `Sold Visas`,
					icon: <BookingsIcon />,
					permission: ["View-Sold-Visas", "Manage-Sold-Visas"],
				},
			],
		},
		{
			id: 16,
			icon: <ManualReservationIcon />,
			text: `${backOffice.manualReservation}`,
			url: "manual-reservations",
			permission: [
				"SBS-Manual-Reservations-View",
				"SBS-Manual-Reservations-Manage",
				"Offline-Manual-Reservations-View",
				"Offline-Manual-Reservations-Manage",
				"Sudan-Manual-Reservations-View",
				"Sudan-Manual-Reservations-Manage",
			],
			show: url.includes("manual-reservations"),
			active: url.includes("manual-reservations"),
			subNavs: [
				{
					id: 160,
					url: "/manual-reservations/sbs-reservations",
					text: `${backOffice.sbsReservation}`,
					icon: <DatePickerIcon />,
					permission: [
						"SBS-Manual-Reservations-View",
						"SBS-Manual-Reservations-Manage",
					],
				},
				{
					id: 161,
					url: "/manual-reservations/offline-reservations?page=1",
					text: `${backOffice.offlineReservation}`,
					icon: <DatePickerIcon />,
					permission: [
						"Offline-Manual-Reservations-View",
						"Offline-Manual-Reservations-Manage",
					],
				},
				{
					id: 162,
					url: "/manual-reservations/sudan-reservations",
					text: `${"Sudan"}`,
					icon: <DatePickerIcon />,
					permission: [
						"Sudan-Manual-Reservations-View",
						"Sudan-Manual-Reservations-Manage",
					],
				},
				{
					id: 200,
					url: "/manual-reservations/Saudi-reservations",
					text: `${"Saudi"}`,
					icon: <DatePickerIcon />,
					permission: [
						"Saudi-Manual-Reservations-View",
						"Saudi-Manual-Reservations-Manage",
					],
				},
			],
		},
		{
			id: 6,
			icon: <AllIcon />,
			text: `${backOffice.requests}`,
			url: "/requests",
			permission: [
				"Visa-Requests-View",
				"Visa-Requests-Manage",
				"View-All-Visas",
				"Transportation-Requests-View",
				"Transportation-Requests-Manage",
				"Ground-Service-Requests-View",
				"Ground-Service-Requests-Manage",
			],
			show: url.includes("/requests"),
			active: url.includes("/requests"),
			subNavs: [
				{
					id: 60,
					url: "/online-visa/requests/waiting",
					text: `${backOffice.allVisaRequests}`,
					icon: <VisaIcon />,
					permission: ["Visa-Requests-View", "Visa-Requests-Manage"],
				},
				{
					id: 61,
					url: "/online-visa/requests/all-visas",
					text: `${backOffice.allVisas}`,
					icon: <VisaIcon />,
					permission: ["View-All-Visas"],
				},
				{
					id: 62,
					url: "/requests/transportation/waiting",
					text: `${backOffice.Transportation}`,
					icon: <TransportationIcon />,
					permission: [
						"Transportation-Requests-View",
						"Transportation-Requests-Manage",
					],
				},
				{
					id: 63,
					url: "/requests/ground/waiting",
					text: `${backOffice.GroundService}`,
					icon: <GroundServiceIcon />,
					permission: [
						"Ground-Service-Requests-View",
						"Ground-Service-Requests-Manage",
					],
				},
			],
		},

		/* 		{
			id: 7,
			icon: <ContractManagementIcon />,
			text: `${backOffice.contractManagement}`,
			url: "/contract",
			permission: [
				"Contract-Requests-View",
				"Contract-Requests-Manage",
				"Contract-List-View",
				"Contract-List-Manage",
			],
			show: url.includes("contract"),
			active: url.includes("contract"),
			subNavs: [
				{
					id: 70,
					url: "/contract-request?page=1",
					text: `${backOffice.contractRequest}`,
					icon: <ContractRequestIcon />,
					permission: ["Contract-Requests-View", "Contract-Requests-Manage"],
				},
				{
					id: 71,
					url: "/contract-list?page=1",
					text: `${backOffice.contractList}`,
					icon: <AllIcon />,
					permission: ["Contract-List-View", "Contract-List-Manage"],
				},
			],
		}, */

		{
			id: 8,
			icon: <CommissionsIcon />,
			text: `${backOffice.commissions}`,
			url: "-commissions-",
			permission: [
				"Platform-Commission-View",
				"Platform-Commission-Manage",
				"Hotel-Commission-View",
				"Hotel-Commission-Manage",
			],
			show: url.includes("-commissions-"),
			active: url.includes("-commissions-"),
			subNavs: [
				{
					id: 80,
					url: "/platform-commissions-list",
					text: `${backOffice.platformCommissions}`,
					icon: <PlatformCommissionsIcon />,
					permission: [
						"Platform-Commission-View",
						"Platform-Commission-Manage",
					],
				},
				// {
				// 	id: 81,
				// 	url: "/hotel-commissions-list",
				// 	text: `${backOffice.hotelCommissions}`,
				// 	icon: <HotelCommissionsIcon />,
				// 	permission: ["Hotel-Commission-View", "Hotel-Commission-Manage"],
				// },
			],
		},
		{
			id: 9,
			icon: <SeasonsIcon />,
			text: `${backOffice.seasons}`,
			url: "/seasons?page=1",
			permission: ["Season-View", "Season-Manage"],
			show: url.includes("/seasons"),
			active: url.includes("/seasons"),
		},
		{
			id: 10,
			icon: <GeneralConfigurationIcon />,
			text: `${backOffice.generalConfiguration}`,
			url: "-configuration",
			permission: [
				"Visa-Configuration-View",
				"Visa-Configuration-Manage",
				"Ground-Configuration-View",
				"Ground-Configuration-Manage",
				"Transportation-Configuration-View",
				"Transportation-Configuration-Manage",
				"Bank-Transfer-Details-View",
				"Bank-Transfer-Details-Manage",
				"Exchange-Rate-View",
				"Exchange-Rate-Manage",
				"View-Countries-Configuration",
				"Manage-Countries-Configuration",
				"View-Attraction",
				"Manage-Attraction",
			],
			show: url.includes("-configuration"),
			active: url.includes("-configuration"),
			subNavs: [
				{
					id: 100,
					url: "/visa-configuration",
					icon: <VisaIcon />,
					text: `${backOffice.visaConfiguration}`,
					permission: ["Visa-Configuration-View", "Visa-Configuration-Manage"],
				},
				{
					id: 101,
					url: "/Ground-configuration",
					icon: <GroundServiceIcon />,
					text: `${backOffice.Groundconfiguration}`,
					permission: [
						"Ground-Configuration-View",
						"Ground-Configuration-Manage",
					],
				},
				{
					id: 102,
					url: "/Transportation-configuration",
					icon: <TransportationIcon />,
					text: `${services.transportation}`,
					permission: [
						"Transportation-Configuration-View",
						"Transportation-Configuration-Manage",
					],
				},
				{
					id: 103,
					url: "/bank-configuration",
					icon: <BankTransferIcon />,
					text: `${backOffice.BankTransferDetails}`,
					permission: [
						"Bank-Transfer-Details-View",
						"Bank-Transfer-Details-Manage",
					],
				},
				{
					id: 104,
					url: "/country-configuration",
					icon: <CountryIcon fill="#99a4b1" />,
					text: `${backOffice.country}`,
					permission: [
						"View-Countries-Configuration",
						"Manage-Countries-Configuration",
					],
				},
				// {
				// 	id: 105,
				// 	url: "/exchange-rate",
				// 	icon: <CommissionsIcon />,
				// 	text: `${backOffice.exchangeRate}`,
				// 	permission: ["Exchange-Rate-View", "Exchange-Rate-Manage"],
				// },
				{
					id: 106,
					icon: <AirlineIcon />,
					text: `Attractions`,
					url: "/attractions",
					permission: ["View-Attraction", "Manage-Attraction"],
					// show: url.includes("/attractions"),
					// active: url.includes("/attractions"),
				},
			],
		},
		{
			id: 11,
			icon: <SuppliersIcon />,
			text: `${backOffice.suppliers}`,
			url: "/suppliers",
			permission: [
				"Suppliers-View",
				"Suppliers-Manage",
				"Property-Management-View",
				"Property-Management-Manage",
				"Contract-Requests-View",
				"Contract-Requests-Manage",
				"Contract-List-View",
				"Contract-List-Manage",
				"Manual-Suppliers-View",
				"Manual-Suppliers-Manage",
			],
			show: url.includes("/suppliers"),
			active: url.includes("/suppliers"),
			subNavs: [
				{
					id: 100,
					url: "/suppliers",
					icon: <SuppliersIcon />,
					text: `${"Online Supplier Companies"}`,
					permission: ["Suppliers-View", "Suppliers-Manage"],
				},
				{
					id: 3,
					icon: <PropertyIcon />,
					text: `${backOffice.propertyManagement}`,
					url: "/property-management?page=1",
					permission: [
						"Property-Management-View",
						"Property-Management-Manage",
					],
					show: url.includes("property-managemen"),
					active: url.includes("property-managemen"),
				},
				{
					id: 7,
					icon: <ContractManagementIcon />,
					text: `${backOffice.contractManagement}`,
					url: "/contract-mangment",
					permission: [
						"Contract-Requests-View",
						"Contract-Requests-Manage",
						"Contract-List-View",
						"Contract-List-Manage",
					],
					show: url.includes("contract"),
					active: url.includes("contract"),
				},
				{
					id: 15,
					icon: <ManualSupplier />,
					text: `Manual Supplier Companies`,
					url: "/manual-suppliers",
					permission: ["Manual-Suppliers-View", "Manual-Suppliers-Manage"],
					show: url.includes("manual-suppliers"),
					active: url.includes("manual-suppliers"),
				},
			],
		},
		{
			id: 12,
			icon: <ActivityLogs />,
			text: `${backOffice.activityLogs}`,
			url: "/activityLogs",
			permission: ["Activity-Logs-Management-View"],
			show: url.includes("/activityLogs"),
			active: url.includes("/activityLogs"),
		},
		{
			id: 13,
			icon: <CheckMarkIcon />,
			text: `${vendor.vendor}`,
			url: "/vendor",
			permission: ["Vendors-Management-View", "Vendors-Management-Manage"],
			show: url.includes("/vendor"),
			active: url.includes("/vendor"),
		},
		{
			id: 14,
			icon: <ToolsIcon />,
			text: `${vendor.tools}`,
			url: "/marketing-offers-list",
			permission: [
				"Marketing-Offers-Management-View",
				"Marketing-Offers-Management-Manage",
				"Popup-Ads-View",
				"Popup-Ads-Manage",
				"promo-View",
				"promo-Manage",
			],
			show: url.includes("/marketing-offers-list"),
			active: url.includes("/marketing-offers-list"),
			subNavs: [
				{
					id: 140,
					url: "/marketing-offers-list",
					icon: <MarketingOfferIcon />,
					text: "Marketing Offers",
					permission: [
						"Marketing-Offers-Management-View",
						"Marketing-Offers-Management-Manage",
					],
				},
				{
					id: 141,
					url: "/popup-ads",
					icon: <MarketingOfferIcon />,
					text: "Popup Ads",
					permission: ["Popup-Ads-View", "Popup-Ads-Manage"],
				},
				{
					id: 142,
					url: "/promo",
					icon: <MarketingOfferIcon />,
					text: "Promo",
					permission: ["promo-View", "promo-Manage"],
				},
			],
		},

		{
			id: 17,
			icon: <TeamIcon />,
			text: "B2C",
			url: "/b2c-users",
			permission: [
				"B2C-Users-View",
				"B2C-Users-Manage",
				"B2C-Bookings-View",
				"B2C-Bookings-Manage",
				"B2C-Transactions-View",
				"B2C-Transactions-Manage",
				"B2C-Commission-View",
				"B2C-Commission-Manage",
				"B2C-Package-View",
				"B2C-Package-Manage",
			],
			show: false,
			active: false,
			subNavs: [
				{
					id: 170,
					url: "/b2c-users",
					icon: <TravelerIcon fill="#99a4b1" />,
					text: `${backOffice.users}`,
					permission: ["B2C-Users-View", "B2C-Users-Manage"],
				},
				{
					id: 171,
					url: "/all-bookings",
					icon: <AllBookings />,
					text: `${backOffice.allBooking}`,
					permission: ["B2C-Bookings-View", "B2C-Bookings-Manage"],
				},
				{
					id: 172,
					url: "/all-transactions",
					icon: <CommissionsIcon />,
					text: `All Transctions`,
					permission: ["B2C-Transactions-View", "B2C-Transactions-Manage"],
				},
				{
					id: 173,
					url: "/b2c-Commission",
					icon: <CommissionsIcon />,
					text: `Commission`,
					permission: ["B2C-Commission-View", "B2C-Commission-Manage"],
				},
				{
					id: 174,
					url: "/b2c-packages",
					icon: <CommissionsIcon />,
					text: `Packages Booking`,
					permission: ["B2C-Package-View", "B2C-Package-Manage"],
				},
			],
		},
		{
			id: 18,
			icon: <FinancialIcon />,
			text: "Financial",
			url: "/Financial",
			permission: [
				"Financial-Online-Transactions-View",
				"Financial-Companies-Wallets-View",
				"Financial-Companies-Wallets-Manage",
			],
			show: false,
			active: false,
			subNavs: [
				// {
				// 	id: 180,
				// 	permission: [],
				// 	url: "/Financial-onlineSuppliers",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.OnlineSuppliers}`,
				// },
				// {
				// 	id: 181,
				// 	permission: [],
				// 	url: "/Financial-offlineSuppliers",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.OfflineSuppliers}`,
				// },
				// {
				// 	id: 182,
				// 	permission: [],
				// 	url: "/Financial-platforms",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.Platforms}`,
				// },
				{
					id: 183,
					url: "/online-transactions",
					icon: <FinancialIcon />,
					text: `${backOffice.onlineTranactions}`,
					permission: ["Financial-Online-Transactions-View"],
				},
				// {
				// 	id: 181,
				// 	permission: [],
				// 	url: "/Financial-onlineSuppliers",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.OnlineSuppliers}`,
				// },
				// {
				// 	id: 182,
				// 	permission: [],
				// 	url: "/Financial-offlineSuppliers",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.OfflineSuppliers}`,
				// },
				// {
				// 	id: 172,
				// 	permission: [],
				// 	url: "/Financial-platforms",
				// 	icon: <FinancialIcon />,
				// 	text: `${backOffice.Platforms}`,
				// },
				{
					id: 184,
					url: "/company-wallet",
					icon: <FinancialIcon />,
					text: `${backOffice.ComapnyWallet}`,
					permission: [
						"Financial-Companies-Wallets-View",
						"Financial-Companies-Wallets-Manage",
					],
				},
			],
		},

		{
			id: 19,
			icon: <AirlineIcon />,
			text: "Airline",
			url: "/airline-reservations",
			permission: [
				"View-Airlines-Reservations",
				"View-Airlines-Requests",
				"Manage-Airlines-Reservations",
				"Manage-Airlines-Requests",
			],

			subNavs: [
				{
					id: 190,
					url: "/airline-reservations",
					icon: <AirlineReservationIcon />,
					text: "Reservations",
					permission: [
						"View-Airlines-Reservations",
						"Manage-Airlines-Reservations",
					],
					show: url.includes("/airline-reservations"),
					active: url.includes("/airline-reservations"),
				},
				{
					id: 191,
					url: "/airline-requests",
					icon: <AirlineRequestIcon />,
					text: "Requests",
					permission: ["View-Airlines-Requests", "Manage-Airlines-Requests"],
					show: url.includes("/airline-requests"),
					active: url.includes("/airline-requests"),
				},
			],
		},
		{
			id: 20,
			icon: <CheckMarkIcon />,
			text: `Commercial Distribution`,
			url: "/commercial-distribution",
			permission: [
				"Commercial-Distribution-View",
				"Commercial-Distribution-Manage",
			],
			show: url.includes("/commercial-distribution"),
			active:
				url.includes("/commercial-distribution") ||
				url.includes("/manage-commercial-distribution"),
		},
		{
			id: 21,
			icon: <SubscriptionPlanIcon />,
			text: `Subscription Plan`,
			url: "/subscription-package-plan",
			permission: ["Subscription-Plan-Manage", "Subscription-Plan-View"],
			show: url.includes("/subscription-plan"),
			active: url.includes("/subscription-plan"),
		},
		{
			id: 22,
			icon: <PlusIcon />,
			text: `Package`,
			url: "/package",
			show: url.includes("/package"),
			permission: [
				"SafaVisa-Packages-List",
				"SafaVisa-Packages-View",
				"SafaVisa-Packages-Add",
				"SafaVisa-Packages-Edit",
				"SafaVisa-Packages-Delete",
				"SafaVisa-Package-Payment-Add",
				"SafaVisa-Package-Payment-Edit",
			],
			active: url.includes("/package"),
		},

	]);
	useEffect(() => {
		async function fetchPermissions() {
			const res = await getPermissions();
			dispatch({ type: "getPermissions", payload: res?.data });
		}
		fetchPermissions();
	}, []);

	return (
		<>
			<nav
				id="sideNav"
				className={`${isOpen && !isMobile ? "opend" : "closed"}`}
			>
				<div style={{ position: "absolute", left: "0", width: "235px" }}>
					<ul className="sidenav_list  scrollable-div">
						<li className="menu-title">Menu</li>
						{/* <li className="sidenav_item">
							<Link
								exact
								to="/"
								className={`sidenav_link ${url == "/" ? "active" : ""}`}
							>
								<span className="sidenav_icon">
									<MainMenuIcon />
								</span>
								<span className="dash-text"> Home</span>
							</Link>
						</li> */}
						{routes.map((dashboard) => {
							const routeHasSubNav =
								dashboard?.subNavs && dashboard?.subNavs.length > 0
									? true
									: false;
							let active = url.includes(dashboard.url);

							return (
								<ShowForPermission permission={dashboard.permission}>
									<li className="sidenav_item">
										<NavLink
											to={dashboard.url}
											className={`sidenav_link ${
												dashboard.show ? "active" : ""
											}`}
											onClick={(e) => {
												if (!routeHasSubNav) {
													activeTab(null);
												}

												routeHasSubNav && e.preventDefault();
												if (isOpen) {
													setRoutes(
														routes.map((route) => {
															return {
																...route,
																show:
																	route.id === dashboard.id
																		? !dashboard.show
																		: false,
																active:
																	route.id === dashboard.id ? !active : false,
															};
														})
													);
												}
											}}
										>
											<span className="sidenav_icon">{dashboard.icon}</span>
											<span className="dash-text"> {dashboard.text}</span>
											{routeHasSubNav ? (
												<span
													className={`sidenav_arrow ${
														dashboard.show && routeHasSubNav ? "toggled" : ""
													}`}
												>
													<Arrow />
												</span>
											) : null}
										</NavLink>
										{/* dropdown */}
										{routeHasSubNav ? (
											<ul className="sidenav_dropdown">
												{isOpen ? (
													<Collapse isOpen={dashboard.show}>
														{dashboard?.subNavs?.map((subNav) => {
															return (
																<ShowForPermission
																	permission={subNav?.permission}
																>
																	<li
																		className="dropdown_item"
																		onClick={() => {
																			activeTab(subNav.id);
																			console.log("id", subNav.id);
																		}}
																	>
																		<NavLink
																			exact
																			to={subNav?.url ?? ""}
																			value={dashboard?.id ?? ""}
																			className={`dropdown_link ${
																				activeid == subNav.id ? "active" : ""
																			}  `}
																			// activeClassName="active"
																		>
																			<span className="sidenav_icon">
																				{subNav?.icon}
																			</span>
																			<span className="dash-text">
																				{subNav?.text}
																			</span>
																		</NavLink>
																	</li>
																</ShowForPermission>
															);
														})}
													</Collapse>
												) : (
													dashboard?.subNavs?.map((subNav) => {
														return (
															<ShowForPermission
																permission={subNav?.permission}
															>
																<li className="dropdown_item">
																	<NavLink
																		exact
																		to={subNav?.url ?? ""}
																		value={dashboard?.id ?? ""}
																		className="dropdown_link"
																		activeClassName="active"
																	>
																		{subNav?.text}
																	</NavLink>
																</li>
															</ShowForPermission>
														);
													})
												)}
											</ul>
										) : null}
									</li>
								</ShowForPermission>
							);
						})}
					</ul>
				</div>
			</nav>
		</>
	);
};

export default SideBar;
