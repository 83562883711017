import companyLoge from "assets/images/default-img.jpg";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import { useGlobalDispatch, useGlobalState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import validate, { isFormValid } from "helpers/validate";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { uploadFile } from "services/auth";
import { fetchImages, updateCompanie, viewCompanie } from "services/companies";
import { fetchCities } from "services/lookups";
import Locale from "translations";

export default function Profile() {
	const { Companies } = Locale;
	const { allCountries } = useGlobalState();
	const [isEdit, setIsEdit] = useState(true);
	const { company_id } = useParams();
	const [company, setCompany] = useState({});
	const [countries, setCountries] = useState([]);
	const [cities, setCities] = useState([]);

	const [errors, setErrors] = useState({});

	let requiredKeys = [
		"name",
		"phone",
		"business_email",
		"country",
		"company_category",
		"services",
	];

	if (company?.company_category?.id === "umrah_company") {
		requiredKeys.push("licence_number");
	}

	let locale = localStorage.getItem("currentLocale") || "en";
	const dispatch = useGlobalDispatch();
	const backOfficeApi = process.env.REACT_APP_API_URL + "/api/files/fetch/";

	let companyServicesLookup = [
		{ id: "hotel", name: Companies.hotel },
		{ id: "flight", name: Companies.flight },
		{ id: "transportation", name: Companies.transportation },
		{ id: "visa", name: Companies.visa },
		{ id: "attractions", name: Companies.attractions },
		{ id: "catering", name: Companies.catering },
		{ id: "package", name: Companies.package },
		{ id: "delegation_management", name: Companies.delegateManagement },
	];

	let companyCategoriesLookup = [
		{ id: "umrah_company", value: "umrah", label: Companies.umrah },
		{ id: "tourism_company", value: "tourism", label: Companies.tourism },
		{ id: "services_company", value: "service", label: Companies.service },
	];

	const servicesBasedOnCategory = (category_id) => {
		if (category_id === "services_company") {
			return companyServicesLookup.filter(
				(t) => t.id === "delegation_management"
			);
		}
		return companyServicesLookup.filter(
			(service) => service.id !== "delegation_management"
		);
	};

	const checkFormErrors = () => {
		let submitErrors = {};
		Object.keys(company).forEach((key) => {
			if (requiredKeys.includes(key)) {
				submitErrors = {
					...submitErrors,
					...validate(
						{
							name: key,
							value: key === "phone" ? company[key].phone : company[key],
						},
						{
							required: true,
							email: key === "business_email",
							min: key === "phone" ? 7 : 0,
						}
					),
				};
			}
		});
		setErrors(submitErrors);
		return submitErrors;
	};

	const updateCompany = async () => {
		const formErrors = checkFormErrors();
		if (isFormValid(formErrors)) {
			const res = await updateCompanie(
				{
					...company,
					country_id: company.country.value,
					city_id: company.city.value,
					phone: `+${company.country.value}${company.phone.phone}`,
					country: null,
					auto_reservation: company?.auto_reservation,
					// share_b2b: company?.share_b2b,
					// share_public: company?.share_public,
					// is_umrah_agent: company.is_umrah_agent,
					share_b2b: true,
					share_public: true,
					is_umrah_agent: true,
					company_category: company.company_category.id,
				},
				company_id
			);
			if (res.status === 200) {
				store.addNotification({
					title: "info!",
					message: res.data.message ?? "updated successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
				setIsEdit(true);
			}
		}
	};

	const paymentCheck = (e) => {
		let checked = e.target.checked;
		let clone = company?.auto_reservation;

		if (checked) {
			clone = checked ? 1 : 0;
		} else {
			clone = 0;
		}

		setCompany({
			...company,
			auto_reservation: clone,
		});
	};

	const paymentShare = (e) => {
		let checked = e.target.checked;
		let clone = company?.share_b2b;

		if (checked) {
			clone = checked ? 1 : 0;
		} else {
			clone = 0;
		}
		setCompany({
			...company,
			share_b2b: clone,
		});
	};
	const sharePubic = (e) => {
		let checked = e.target.checked;
		let clone = company?.share_public;
		if (checked) {
			clone = checked ? 1 : 0;
		} else {
			clone = 0;
		}
		setCompany({
			...company,
			share_public: clone,
		});
	};
	const umrahAgent = (e) => {
		let checked = e.target.checked;
		let clone = company?.is_umrah_agent;
		if (checked) {
			clone = checked ? 1 : 0;
		} else {
			clone = 0;
		}
		setCompany({
			...company,
			is_umrah_agent: clone,
		});
	};

	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries.map((t) => ({
				value: t.id,
				label: t.names[locale],
				code: t.country_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, []);

	// get company data
	useEffect(() => {
		async function getCompanyData() {
			const res = await viewCompanie(company_id);
			if (res?.status === 200 || res?.status === 201) {
				const data = {
					business_email: res?.data?.data.business_email,
					name: res?.data?.data.name,
					phone: res?.data?.data.phone,
					// safa_key: res?.data?.data.safa_key,
					country: {
						value: res?.data?.data?.country?.id,
						label: res?.data?.data?.country?.name,
					},
					city_id: res?.data?.data?.city?.id,
					city: {
						value: res?.data?.data?.city?.id,
						label: res?.data?.data?.city?.name,
					},
					avatar: res?.data?.data?.avatar,
					address: res?.data?.data?.address,
					share_b2b: res?.data?.data?.share_b2b,
					auto_reservation: res?.data?.data?.auto_reservation,
					share_public: res?.data?.data?.share_public,
					is_umrah_agent: res?.data?.data?.is_umrah_agent,
					company_category:
						companyCategoriesLookup.find(
							(t) => t.id === res?.data?.data?.company_category
						) || {},
					services: res?.data?.data?.services || [],
					licence_number: res?.data?.data?.licence_number,
					whatsapp_number: res?.data?.data?.whatsapp_number,
				};
				console.log(
					"services",
					res?.data?.data?.services.map((service) =>
						companyServicesLookup.find((t) => t.id === service)
					) || []
				);
				dispatch({ type: "company_name", payload: data?.name });

				setCompany(data);
			}
		}

		if (company_id) {
			getCompanyData();
		}
	}, [company_id]);

	// get company logo
	useEffect(() => {
		async function getCompanyLogo() {
			if (company.imgFile) {
				let formData = new FormData();
				formData.append("file", company.imgFile);
				formData.append("name", company.imgFile.name);
				formData.append("bucket", "user");
				const resFile = await uploadFile(formData);

				if (resFile.status === 200) {
					setCompany({
						...company,
						avatar: resFile.data?.uuid,
					});
				}
			}
		}

		getCompanyLogo();
	}, [company.imgFile]);

	// get company avatar
	useEffect(() => {
		async function getCompanyAvatar() {
			if (company.avatar) {
				const res = await fetchImages(company?.avatar);
				setCompany({ ...company, displayImg: res?.data?.data?.avatar });
			}
		}
		getCompanyAvatar();
	}, [company.avatar]);

	// get cities based on country
	useEffect(() => {
		const getCities = async () => {
			if (company?.country?.value) {
				const res = await fetchCities(company?.country?.value);
				console.log("res101", res?.data);
				const format = res?.data?.map((t) => ({
					value: t.id,
					label: t.names[locale],
				}));
				setCities(format);
			}
		};
		getCities();
	}, [company.country]);

	console.log("company", company);

	return (
		<div className=" px-5 ">
			<div className="row my-4">
				<div className="col-4">
					<figure className=" shape-border w-75 mb-0">
						<img
							src={
								company.avatar ? backOfficeApi + company.avatar : companyLoge
							}
							alt=""
							className="w-100"
						/>
					</figure>

					{isEdit ? (
						<div className="mt-4">
							<div>
								<input
									disabled={isEdit}
									className="form-check-input "
									type="checkbox"
									id="payment"
									onChange={paymentCheck}
									checked={company.auto_reservation == 1 ? true : false}
									style={{ fontSize: "21px" }}
								/>
								<label
									className="form-check-label pointer mx-2 mt-1"
									htmlFor="payment"
									style={{ fontSize: "17px" }}
								>
									pay Later
								</label>
							</div>
							<div>
								<input
									disabled={isEdit}
									className="form-check-input"
									type="checkbox"
									id="share"
									onChange={paymentShare}
									checked={company.share_b2b == 1 ? true : false}
									style={{ fontSize: "21px" }}
								/>
								<label
									className="form-check-label pointer mx-2 mt-1"
									htmlFor="share"
									style={{ fontSize: "17px" }}
								>
									share b2b
								</label>
							</div>
							<div className="mt-2">
								<input
									disabled={isEdit}
									className="form-check-input"
									type="checkbox"
									id="publicPage"
									onChange={sharePubic}
									checked={company.share_public == 1 ? true : false}
									style={{ fontSize: "21px" }}
								/>
								<label
									className="form-check-label pointer mx-2 mt-1"
									htmlFor="publicPage"
									style={{ fontSize: "17px" }}
								>
									Public Company Page
								</label>
							</div>

							<div className="mt-2">
								<input
									disabled={isEdit}
									className="form-check-input"
									type="checkbox"
									id="umrah_agent"
									onChange={umrahAgent}
									checked={company.is_umrah_agent == 1 ? true : false}
									style={{ fontSize: "21px" }}
								/>
								<label
									className="form-check-label pointer mx-2 mt-1"
									htmlFor="umrah_agent"
									style={{ fontSize: "17px" }}
								>
									Umrah Agent
								</label>
							</div>
						</div>
					) : null}

					{isEdit ? null : (
						<div className=" shape-border mt-1 w-75 border-0">
							<button className="file-btn w-100 ">
								{Companies.changeLogo}
								<input
									type="file"
									className="custom-file"
									accept="image/png, image/gif, image/jpeg"
									onChange={(e) => {
										console.log(e.target.files[0]);
										setCompany({
											...company,
											displayImg: URL.createObjectURL(e.target.files[0]),
											imgFile: e.target.files[0],
										});
									}}
								/>
							</button>
						</div>
					)}
				</div>
				<div className="col-8">
					{!isEdit ? (
						<div className="row">
							<div className="col-6">
								<TextField
									type="text"
									placeholder={Companies.name}
									label={Companies.name}
									hasLabel={true}
									disabled={isEdit}
									value={company?.name?.en}
									onChange={(e) => {
										setCompany({
											...company,
											name: { ...company?.name, en: e.target.value },
										});
									}}
									color={errors?.name?.required ? "danger" : ""}
									errors={errors?.name}
								/>
							</div>
							<div className="col-6">
								<TextField
									type="text"
									placeholder={Companies.nameAr}
									label={Companies.nameAr}
									hasLabel={true}
									disabled={isEdit}
									value={company?.name?.en}
									onChange={(e) => {
										setCompany({
											...company,
											name: { ...company?.name, en: e.target.value },
										});
									}}
									color={errors?.name?.required ? "danger" : ""}
									errors={errors?.name}
								/>
							</div>
							<div className="col-12">
								<TextField
									type="text"
									placeholder={Companies.phoneNumber}
									label={Companies.phoneNumber}
									hasLabel={true}
									disabled={isEdit}
									value={company?.phone?.phone}
									min={7}
									onChange={(e) => {
										setErrors({
											...errors,
											...validate(
												{ name: "phone", value: e.target.value },
												{ required: true, min: 7 }
											),
										});
										setCompany({
											...company,
											phone: {
												phone: e.target.value,
												phone_code: `+${company.country.value}`,
											},
										});
									}}
									color={
										errors?.phone?.required || errors?.phone?.min
											? "danger"
											: ""
									}
									errors={errors?.phone}
								/>
							</div>
							{/* <div className="col-6">
								<TextField
									type="text"
									placeholder={"Safa Key"}
									label={"Safa Key"}
									hasLabel={true}
									disabled={isEdit}
									value={company?.safa_key}
									onChange={(e) => {
										setCompany({
											...company,
											safa_key: e.target.value,
										});
									}}
								/>
							</div> */}
							{/* <div className="col-5">
          <TextField
            type="text"
            placeholder={Companies.mobileNumber}
            hasLabel={false}
            disabled={isEdit}
            value={company?.phone?.phone}
            onChange={(e) => {
              setCompany({
                ...company,
                mobile: {
                  ...company.phone,
                  mobile: e.target.value,
                },
              });
            }}
          />
        </div> */}
							<div className="col-6">
								<TextField
									type="text"
									placeholder={Companies.email}
									label={Companies.email}
									hasLabel={true}
									disabled={isEdit}
									value={company?.business_email}
									onChange={(e) => {
										setCompany({
											...company,
											business_email: e.target.value,
										});

										setErrors({
											...errors,
											...validate(
												{ name: "business_email", value: e.target.value },
												{ required: true, email: true }
											),
										});
									}}
									color={
										errors?.business_email?.required ||
											errors?.business_email?.email
											? "danger"
											: ""
									}
									errors={errors?.business_email}
								/>
							</div>
							<div className="col-6">
								<SelectField
									hasLabel={true}
									placeholder={Companies.country}
									label={Companies.country}
									name="status"
									disabled={isEdit}
									options={countries}
									value={company.country}
									onChange={(e) => {
										setCompany({
											...company,
											country: e,
										});
									}}
									color={errors?.country?.required ? "danger" : ""}
									errors={errors?.country}
								/>
							</div>
							<div className="col-12">
								<SelectField
									hasLabel={true}
									placeholder={Companies.city}
									label="city"
									name="status"
									disabled={isEdit}
									options={cities}
									value={company?.city}
									onChange={(e) => {
										setCompany({
											...company,
											city: e,
										});
									}}
								/>
							</div>
							<div className="col-12">
								<TextField
									type="text"
									placeholder={"Address"}
									label={"Address"}
									hasLabel={true}
									disabled={isEdit}
									value={
										company?.address
											? company?.address[locale] ?? company?.address?.ar
											: ""
									}
									onChange={(e) => {
										setCompany({
											...company,
											address: { en: e.target.value },
										});
									}}
								/>
							</div>
							<div className="col-12">
								<TextField
									type="text"
									placeholder={"Whatsapp Number"}
									label={"Whatsapp Number"}
									hasLabel={true}
									disabled={isEdit}
									value={
										company?.whatsapp_number ? company?.whatsapp_number : ""
									}
									onChange={(e) => {
										setCompany({
											...company,
											whatsapp_number: e.target.value,
										});
									}}
								/>
							</div>
							<div className="col-12">
								<SelectField
									placeholder={"Company Category"}
									label={"Company Category"}
									hasLabel={true}
									disabled={isEdit}
									options={companyCategoriesLookup || []}
									value={company?.company_category}
									onChange={(e) => {
										console.log({ e });
										setCompany({
											...company,
											company_category: e,
											licence_number:
												e.id === "umrah_company" ? company?.licence_number : "",
											services: [],
										});
										setErrors({
											...errors,
											...validate(
												{ name: "company_category", value: e },
												{ required: true }
											),
										});
									}}
									color={errors?.company_category?.required ? "danger" : ""}
									errors={errors?.company_category}
								/>
							</div>
							{company?.company_category?.id === "umrah_company" ? (
								<div className="col-12">
									<TextField
										type="text"
										placeholder={"Licence Number"}
										label={"Licence Number"}
										hasLabel={true}
										disabled={isEdit}
										value={
											company?.licence_number ? company?.licence_number : ""
										}
										onChange={(e) => {
											setCompany({
												...company,
												licence_number: e.target.value,
											});
											setErrors({
												...errors,
												...validate(
													{ name: "licence_number", value: e.target.value },
													{ required: true }
												),
											});
										}}
										color={errors?.licence_number?.required ? "danger" : ""}
										errors={errors?.licence_number}
									/>
								</div>
							) : null}
							<div className="col-12">
								<div className="row mb-3">
									{company?.company_category?.id
										? servicesBasedOnCategory(
											company?.company_category?.id
										).map((service) => (
											<div className="col-6 mt-2" key={service.id}>
												<input
													className="form-check-input"
													type="checkbox"
													id={service.name}
													label={service.name}
													value={service.id}
													checked={company?.services?.includes(service.id)}
													onChange={(e) => {
														let services = [];
														if (e.target.checked) {
															services = [...company?.services, service.id];
														} else {
															services = company?.services?.filter(
																(i) => i !== service.id
															);
														}
														setCompany({
															...company,
															services,
														});
														setErrors({
															...errors,
															...validate(
																{ name: "services", value: services },
																{ required: true }
															),
														});
													}}
													style={{ fontSize: "21px" }}
												/>
												<label
													className="form-check-label pointer mx-2 mt-1"
													htmlFor={service.name}
												>
													{service.name}
												</label>
											</div>
										))
										: null}
								</div>
								{errors?.services?.required ? (
									<p className="text-danger">
										You Should select at least one service
									</p>
								) : null}
							</div>
							<div className="col-6 mt-2">
								<input
									disabled={isEdit}
									className="form-check-input"
									type="checkbox"
									id="payment"
									onChange={paymentCheck}
									checked={company.auto_reservation == 1 ? true : false}
									style={{ fontSize: "21px" }}
								/>
								<label
									className="form-check-label pointer mx-2 mt-1"
									for="payment"
									style={{ fontSize: "17px" }}
								>
									pay Later
								</label>
							</div>
						</div>
					) : (
						<>
							<div className="row">
								<div className="col-4">
									<h1 className="pro-label">{Companies.nameAr} </h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.name?.en || "--"}</h4>
								</div>
								<div className="col-4">
									<h1 className="pro-label">{Companies.name}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.name?.en || "--"}</h4>
								</div>
								<div className="col-4">
									<h1 className="pro-label">{Companies.email}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">
										{company?.business_email || "--"}
									</h4>
								</div>
								<div className="col-4">
									<h1 className="pro-label">{Companies.phoneNumber}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.phone?.phone || "--"}</h4>
								</div>
								{/* <div className="col-4">
									<h1 className="pro-label">{"Safa Key"}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.safa_key || "--"}</h4>
								</div> */}
								<div className="col-4">
									<h1 className="pro-label">{Companies.country}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">
										{company?.country?.label || "--"}
									</h4>
								</div>
								<div className="col-4">
									<h1 className="pro-label">{"city"}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">{company?.city?.label || "--"}</h4>
								</div>
								<div className="col-4">
									<h1 className="pro-label">{"Company Category"}</h1>
								</div>
								<div className="col-8">
									<h4 className="pro-data">
										{company?.company_category?.label || "--"}
									</h4>
								</div>

								{company?.company_category?.id === "umrah_company" ? (
									<>
										<div className="col-4">
											<h1 className="pro-label">{"Licence Number"}</h1>
										</div>
										<div className="col-8">
											<h4 className="pro-data">
												{company?.licence_number || "--"}
											</h4>
										</div>
									</>
								) : null}

								<div className="col-4">
									<h1 className="pro-label">{"Services: "}</h1>
								</div>

								{company?.services?.length > 0 ? (
									<div className="d-flex flex-wrap" style={{ gap: 12 }}>
										{servicesBasedOnCategory(company.company_category?.id).map(
											(service) => (
												<div className="" key={service.id}>
													<input
														disabled={isEdit}
														className="form-check-input"
														type="checkbox"
														id={service.id}
														checked={company?.services?.includes(service.id)}
														style={{ fontSize: "21px" }}
													/>
													<label
														className="form-check-label pointer mx-2 mt-1"
														htmlFor={service.id}
														style={{ fontSize: "17px" }}
													>
														{service?.name}
													</label>
												</div>
											)
										)}
									</div>
								) : (
									"--"
								)}

								{/* <div className="col-4"></div> */}
							</div>
						</>
					)}
					<div className=" col-12 p-0 d-flex justify-content-end mt-3">
						<ShowForPermission permission="Tourism-Company-Manage">
							{isEdit ? (
								<button
									className="btn companies-btn mt-2 w-25"
									onClick={() => {
										setIsEdit(!isEdit);
									}}
								>
									{Companies.edit}
								</button>
							) : (
								<button
									className="btn companies-btn w-25"
									onClick={updateCompany}
								>
									{Companies.saveChanges}
								</button>
							)}
						</ShowForPermission>
					</div>
				</div>
			</div>
		</div>
	);
}
