import DatePickerField from "components/shared/DatePickerField";
import SelectField from "components/shared/SelectField";
import TextField from "components/shared/TextField";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { isInclusivelyBeforeDay } from "react-dates";
import { MultiSelect } from "react-multi-select-component";
import Locale from "translations";

export default function VisaRowDetails({
	allConfigData,
	configDataItem,
	countries,
	visaPermissionManage,
	handleChangeinArray,
	visaTypeIndex,
	residenceIndex,
	currencies,
	handleCopy,
	handleAddPackage,
	countryBase,
	consumers,
	deleteVisa,
}) {
	const { backOffice } = Locale;
	console.log(configDataItem, "configDataItem");
	const valueRenderer = (selected) => {
		if (!selected.length) {
			return "No Item Selected";
		}
		return "Item Selected  " + selected.length;
	};
	console.log("consumers", consumers);
	console.log("configDataItem?.consumers", configDataItem?.consumers);
	return (
		<div className="row visa-row-details d-flex flex-wrap w-100">
			{/* residences */}
			{countries && countries.length > 0 ? (
				<div className="col-md-4 mt-3">
					<span>{backOffice.residence}</span>
					<div className="w-100">
						<MultiSelect
							isDisabled={!visaPermissionManage}
							label={backOffice.residence}
							placeholder={backOffice.residence}
							options={countries.slice(1)}
							id="residences"
							name="residences"
							isMulti
							valueRenderer={valueRenderer}
							onChange={(e) => {
								if (e == null || e.length === 0) {
									handleChangeinArray(
										"residencesNames",
										null,
										visaTypeIndex,
										residenceIndex
									);
									return;
								}
								if (
									e &&
									e.length > 0 &&
									e.filter((res) => res.label === "All").length > 0
								) {
									handleChangeinArray(
										"residencesNames",
										countries.slice(1),
										visaTypeIndex,
										residenceIndex
									);
								} else {
									handleChangeinArray(
										"residencesNames",
										e,
										visaTypeIndex,
										residenceIndex
									);
								}
							}}
							value={
								configDataItem?.residencesNames == null
									? []
									: configDataItem?.residencesNames?.length === 0
									? { label: "All", value: 0 }
									: configDataItem?.residencesNames?.length > 0 &&
									  configDataItem?.residencesNames[0]?.country_id
									? configDataItem?.residencesNames?.map((item) => {
											return {
												value: item.country_id,
												label: item.name,
											};
									  })
									: configDataItem?.residencesNames?.map((item) => {
											return {
												value: item?.value,
												label: item?.label,
											};
									  })
							}
						/>
					</div>
				</div>
			) : null}
			{/* nationality */}
			{countries && countries.length > 0 ? (
				<div className="col-md-4 mt-3">
					<span>{backOffice.nationality}</span>
					<MultiSelect
						isDisabled={!visaPermissionManage}
						label={backOffice.nationality}
						placeholder={backOffice.nationality}
						name="nationalities"
						valueRenderer={valueRenderer}
						isMulti
						value={
							configDataItem?.nationalities == null
								? []
								: configDataItem?.nationalities?.length === 0
								? { label: "All", value: 0 }
								: configDataItem?.nationalities?.length > 0 &&
								  configDataItem?.nationalities[0]?.country_id
								? configDataItem?.nationalities?.map((item) => {
										return {
											value: item.country_id,
											label: item.name,
										};
								  })
								: configDataItem?.nationalities?.map((item) => {
										return {
											value: item?.value,
											label: item?.label,
										};
								  })
						}
						onChange={(e) => {
							if (e == null || e.length === 0) {
								handleChangeinArray(
									"nationalities",
									null,
									visaTypeIndex,
									residenceIndex
								);
								return;
							}
							if (
								e &&
								e.length > 0 &&
								e.filter((res) => res.label === "All").length > 0
							) {
								handleChangeinArray(
									"nationalities",
									countries.slice(1),
									visaTypeIndex,
									residenceIndex
								);
							} else {
								handleChangeinArray(
									"nationalities",
									e,
									visaTypeIndex,
									residenceIndex
								);
							}
						}}
						options={countries.slice(1)}
					/>
				</div>
			) : null}

			{/* price */}
			<div className="col-md-2">
				<TextField
					disabled={!visaPermissionManage}
					type="text"
					label={backOffice.price}
					placeholder={backOffice.price}
					name="price"
					id="price"
					onChange={(e) => {
						handleChangeinArray(
							"price",
							e.target.value,
							visaTypeIndex,
							residenceIndex
						);
					}}
					value={configDataItem?.price}
				/>
			</div>

			{/* currency */}
			<div className="col-md-2">
				<SelectField
					disabled={!visaPermissionManage}
					label={backOffice.currency}
					placeholder={backOffice.currency}
					options={currencies}
					id="currency"
					name="currency"
					onChange={(e) => {
						handleChangeinArray("currency", e, visaTypeIndex, residenceIndex);
					}}
					value={
						configDataItem?.currency &&
						!configDataItem?.currency.hasOwnProperty("label")
							? {
									value: configDataItem?.currency,
									label: configDataItem?.currency,
							  }
							: configDataItem.currency
					}
				/>
			</div>

			{/* start date */}
			<div className="col-md-4  dataPicker-configRow">
				<DatePickerField
					disabled={!visaPermissionManage}
					hasLabel={true}
					label={"Start Date"}
					placeholder={"Start Date"}
					name="start_date"
					id="start_date"
					date={
						configDataItem?.start_date
							? moment(configDataItem?.start_date)
							: configDataItem?.start_date
					}
					onChangeDate={(e) => {
						handleChangeinArray("start_date", e, visaTypeIndex, residenceIndex);
					}}
					isOutsideRange={(day) => day.isBefore(moment().add(-1, "days"))}
				/>
			</div>

			{/* end date */}
			<div className="col-md-4 dataPicker-configRow">
				<DatePickerField
					disabled={!visaPermissionManage}
					hasLabel={true}
					label={"End Date"}
					placeholder={"End Date"}
					name="end_date"
					id="end_date"
					date={
						configDataItem?.end_date
							? moment(configDataItem?.end_date)
							: configDataItem?.end_date
					}
					onChangeDate={(e) => {
						handleChangeinArray("end_date", e, visaTypeIndex, residenceIndex);
					}}
					isOutsideRange={(day) => isInclusivelyBeforeDay(day, moment())}
					value
				/>
			</div>

			{/* expiration days */}
			<div className="col-md-2">
				<TextField
					disabled={!visaPermissionManage}
					type="text"
					label={"Expire Days"}
					placeholder={"Expire Days"}
					name="expiration_days"
					id="expiration_days"
					onChange={(e) => {
						handleChangeinArray(
							"expiration_days",
							e.target.value,
							visaTypeIndex,
							residenceIndex
						);
					}}
					value={configDataItem?.expiration_days}
				/>
			</div>

			{/* processing time */}
			<div className="col-md-2">
				<TextField
					disabled={!visaPermissionManage}
					type="text"
					label="Processing Time"
					placeholder={"Processing Time"}
					name="processing_time"
					id="processing_time"
					onChange={(e) => {
						handleChangeinArray(
							"processing_time",
							e.target.value,
							visaTypeIndex,
							residenceIndex
						);
					}}
					value={configDataItem?.processing_time}
				/>
			</div>

			<ShowForPermission permission="Visa-Configuration-Manage">
				<div
					className={`${
						allConfigData?.country_code === "966" ? "col-md-12" : "col-md-12"
					}  d-flex justify-content-between align-items-center mt-3 flex-wrap`}
				>
					{/* toggle */}
					<div className="d-flex align-items-center">
						{/* <Switch
							width={45}
							height={22}
							handleDiameter={10}
							uncheckedIcon={false}
							checkedIcon={false}
							className="mx-2 align-middle"
							onColor="#027B30"
							onChange={(e) => {
								handleChangeinArray(
									"share_with_consumer",
									e,
									visaTypeIndex,
									residenceIndex
								);
							}}
							checked={configDataItem?.share_with_consumer}
						/>
						<label
							className="m-0"
							htmlFor={`share-with-consumer-${countryBase}${visaTypeIndex}${residenceIndex}`}
						>
							Share for supplier (Umrah Holidays)
						</label> */}
						<div>
							<span>Share for </span>

							<MultiSelect
								isDisabled={!visaPermissionManage}
								label={"consumer"}
								placeholder={"consumer"}
								options={consumers}
								id="consumer"
								name="consumer"
								isMulti
								valueRenderer={valueRenderer}
								onChange={(e) => {
									if (e == null || e.length === 0) {
										handleChangeinArray(
											"consumers",
											null,
											visaTypeIndex,
											residenceIndex
										);
										return;
									}
									if (
										e &&
										e.length > 0 &&
										e.filter((res) => res.label === "All").length > 0
									) {
										handleChangeinArray(
											"consumers",
											consumers,
											visaTypeIndex,
											residenceIndex
										);
									} else {
										handleChangeinArray(
											"consumers",
											e,
											visaTypeIndex,
											residenceIndex
										);
									}
								}}
								value={
									configDataItem?.consumers == null
										? []
										: configDataItem?.consumers?.length === 0
										? []
										: configDataItem?.consumers?.length > 0
										? configDataItem?.consumers?.map((item) => {
												return {
													value: item.id || item.value,
													label: item.name || item.label,
												};
										  })
										: []
								}
							/>
						</div>
					</div>
					<button
						className="btn copyIcon-configRow p-0"
						onClick={() => handleAddPackage(visaTypeIndex, residenceIndex)}
						style={{ whiteSpace: "pre" }}
					>
						<i className="fas fa-plus mx-1"></i>
						Add Package
					</button>

					<button
						className="btn copyIcon-configRow p-0"
						onClick={() => handleCopy(visaTypeIndex, residenceIndex)}
						style={{ whiteSpace: "pre" }}
					>
						<i className="fas fa-copy mx-1"></i>
						Duplicate
					</button>

					{/* delete visa row */}
					<button
						className="btn-link text-danger mx-1"
						onClick={() => {
							deleteVisa(visaTypeIndex, residenceIndex);
						}}
						href={() => false}
						style={{ whiteSpace: "pre", fontSize: "13px" }}
					>
						<i className="fas fa-trash mx-1"></i>
						Remove
					</button>
				</div>
			</ShowForPermission>
		</div>
	);
}
