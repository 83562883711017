import Model from "components/model";
import { useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { deleteOffer } from "services/platform";
import Locale from "translations";
import PlatformOffers from "./AddEditPlartformOffer";
import ListPlatformOffers from "./PlartformOffer";

export default function PlatfromSpecialOffer({
	specialOfferList,
	setSpecialOfferList,
	setReload,
}) {
	const { backOffice } = Locale;
	const { id } = useParams();
	const [OfferItem, setOfferItem] = useState({});
	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
		index: "",
	});
	const toggle = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: "",
			title: "",
			index: "",
		});
	};

	function changeValueOffer(index, name, value, data) {
		let list = specialOfferList;
		setSpecialOfferList([]);
		list[index] = { ...list[index], ...data, [name]: value };

		setSpecialOfferList(list);

		setModal({
			isOpen: !modal.isOpen,
			type: "model",
			title: "edit",
			index: index,
		});
	}

	const deleteOfferfn = async (e, index) => {
		if (id && e.id != null) {
			const res = await deleteOffer(id, e.id);
			if (res.status === 200) {
				// dispatch({ type: "progress", step: "2", current: "2" });
				// propertyDispatch({ type: "hotelMainDetails", payload: res.data });
				removeItemOffer(index);
				store.addNotification({
					title: "Done!",
					message: res.data.message,
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});
			}
		}
	};

	const removeItemOffer = async (index) => {
		let clonedCommissions = [...specialOfferList];
		clonedCommissions.splice(index, 1);
		setSpecialOfferList(clonedCommissions);
	};

	return (
		<div className="row custom--row">
			<div className="col">
				<div className="personal-data">
					{id ? (
						<>
							<div className="col-md-12 head-manager p-0">
								<p>{backOffice.SpecialOffers}</p>
							</div>

							<div className="col our-border px-4">
								<table className="custom-table back-office-table table table-striped">
									<thead>
										<tr>
											<th style={{ maxWidth: "320px", width: "320px" }}>
												{backOffice.From}
											</th>
											<th style={{ maxWidth: "320px", width: "320px" }}>
												{backOffice.To}
											</th>
											<th style={{ maxWidth: "320px", width: "320px" }}>
												{backOffice.Offer}
											</th>
											<th>&nbsp;</th>
										</tr>
									</thead>
									<tbody>
										{specialOfferList
											? specialOfferList?.map((item, index) => (
													<ListPlatformOffers
														item={item}
														index={index}
														changeValueOffer={changeValueOffer}
														deleteOfferfn={deleteOfferfn}
														setOfferItem={setOfferItem}
													/>
												))
											: null}
									</tbody>
								</table>
							</div>
						</>
					) : null}
					{id ?
						<a
							className="btn btn-lg text-success d-inline-block my-2 "
							href={() => false}
							onClick={() => {
								let x = {
									edit: true,
									form: new Date(),
									offer: 0,
									id: null,
									to: new Date(),
								};
								setModal({
									isOpen: !modal.isOpen,
									type: "model",
									title: "add",
									index: "",
								});
								setOfferItem({ ...x });
							}}
						>
							<i className="fas fa-plus-circle"></i> {backOffice.AddSpecialOffers}
						</a>
					: 
						null
					}
				</div>
			</div>
			<Model
				isOpen={modal.isOpen}
				type={modal.type}
				title={`${modal.title} Special Offers `}
				toggle={toggle}
				size="lg"
				hasFooter={false}
				hasHeader={true}
			>
				<PlatformOffers
					item={OfferItem}
					index={modal.index}
					toggle={toggle}
					title={modal.title}
					setReload={setReload}
				/>
			</Model>
		</div>
	);
}
