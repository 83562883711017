import datePickerIcon from "assets/images/datePicker.svg";

import { useGlobalState } from "context/global";
import moment from "moment";
import { useState } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import Locale from "translations";

export default function DatePickerField(props) {
	const [focused, setFocused] = useState(false);
	const { locale } = useGlobalState();
	const { commons } = Locale;

	const years = () => {
		const currentYear = new Date().getFullYear();
		let startYear;
		let endYear;
		let yearsRange;
		// if start date and end date is passed to props
		if (props.startYear && props.endYear) {
			startYear =
				props.startYear instanceof moment
					? moment(props.startYear).format("YYYY")
					: +props.startYear;
			endYear =
				props.endYear instanceof moment
					? moment(props.endYear).format("YYYY")
					: +props.endYear;
			endYear = startYear > endYear ? startYear : endYear;
			yearsRange = +endYear - +startYear;
		} else {
			startYear = currentYear - 50;
			endYear = currentYear + 50;
			yearsRange = endYear - startYear;
		}
		return [
			...Array.from({ length: yearsRange + 1 }, (v, i) => +startYear + i),
		];
	};

	const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
		return (
			<div
				style={{ display: "flex", justifyContent: "center" }}
				className="datePicker-years"
			>
				<div className="">
					<select
						value={month.month()}
						onChange={(e) => onMonthSelect(month, e.target.value)}
					>
						{moment.months().map((label, value) => (
							<option key={value} value={value}>
								{label}
							</option>
						))}
					</select>
				</div>
				<div className="">
					<select
						value={month.year()}
						onChange={(e) => onYearSelect(month, e.target.value)}
					>
						{years().map((res) => {
							return (
								<option key={res} value={moment(res)}>
									{res}
								</option>
							);
						})}
					</select>
				</div>
			</div>
		);
	};
	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					message.push(` should be at least ${props.min} chars`);
				} else if (k === "email" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "phone" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "number" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "date" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "serverError" && v) {
					if (Array.isArray(v)) {
						v.forEach((i) => message.push(i));
					} else {
						message.push(v);
					}
				}
			}
			if (message.length === 1) {
				return `${props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};

	return (
		<>
			{/* Label */}
			<div
				className={`my-1 control-field + ${
					props.color ? " control-field--" + props.color : ""
				} ${props.disabled ? "isDisabled" : ""} ${
					props.labelInner ? "control-field-inner" : ""
				}`}
				style={{ padding: "10px" }}
			>
				{props.label ? (
					<label
						className={`control-field__label ${
							props.color ? " control-field__label--" + props.color : ""
						}`}
					>
						{props.label}
					</label>
				) : null}
				<div
					className={`control-field__body  position-relative ${
						props.disabled && props.readOnly ? "isDisabled" : ""
					} ${props.labelInner ? "innerPadding" : "newFixPadding"} `}
					style={{
						border: "1px solid #d1d6dd",
						padding: "0.625rem 0.625rem 0.3125rem 0.625rem",
					}}
				>
					{props.labelInner && (
						<span
							className={`font-weight-bolder ${
								props.color ? " control-field__label--" + props.color : ""
							}`}
						>
							{props.label}
						</span>
					)}
					{/* Date Inputs */}
					<div className="no-gutters">
						<SingleDatePicker
							numberOfMonths={1}
							onOutsideClick={true}
							date={props.date}
							noBorder={true}
							disabled={props.disabled}
							focused={focused}
							displayFormat={() => "DD/MM/YYYY"}
							onFocusChange={({ focused }) => setFocused(focused)}
							onDateChange={props.onChangeDate}
							// Disable dates before minDate
							isOutsideRange={(day) =>
								props.minDate && day.isBefore(props.minDate, "day")
							}
							renderMonthElement={(e) =>
								props.hasYears !== undefined
									? renderMonthElement(e)
									: e.month.format("MMMM YYYY")
							}
							placeholder={props.placeholder}
							hideKeyboardShortcutsPanel={true}
							customArrowIcon={true}
							isRTL={locale === "en" ? false : true}
							anchorDirection={locale === "en" ? "left" : "right"}
							{...props}
						/>
						{/* <i className="fas fa-calendar-alt date-icon p-2"></i> */}
						<img
							className="date-icon p-2"
							src={datePickerIcon}
							alt=""
							width={40}
							height={40}
						/>
					</div>
				</div>
			</div>

			{/* Feedback */}
			{/* <small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small> */}
			{/* Error Message */}
			<small
				className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
			>
				{generateErrorsMsgs(props.errors)}
			</small>
		</>
	);
}

DatePickerField.defaultProps = {
	hasLabel: true,
};
